export function sortTableFieldByTextual(data: any[], field: string, order: 'asc' | 'desc'): any[] {
  return data.sort((a, b) => {
    if (order === 'asc') {
        return a[field].value.localeCompare(b[field].value);
    } 
    return b[field].value.localeCompare(a[field].value);
  });
};

export function sortTableFieldByNumeric(data: any[], field: string, order: 'asc' | 'desc'): any[] {
  return data.sort((a, b) => {
    const aValue = parseInt(a[field].value);
    const bValue = parseInt(b[field].value);

    if (order === 'asc') {
      if (aValue > bValue) {
        return 1;
      } else if (aValue < bValue) {
        return -1;
      }

      return 0;
    } 

    // desc
    if (aValue < bValue) {
      return 1;
    } else if (aValue > bValue) {
      return -1;
    }

    return 0;
  });
};

export function sortTableFieldByDate(data: any[], field: string, order: 'asc' | 'desc'): any[] {
  return data.sort((a, b) => {
    const dateA = new Date(a[field].value);
    const dateB = new Date(b[field].value);

    if (order === 'asc') {
      if (+dateA > +dateB) {
        return 1;
      } else if (+dateA < +dateB) {
        return -1;
      }

      return 0;
    }

    // desc
    if (+dateA < +dateB) {
      return 1;
    } else if (+dateA > +dateB) {
      return -1;
    }

    return 0;
  });
};