import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

import { ICustomLocationModel, ILocationModel } from '../../../models/api_models';
import createGeoIsLinkLink from '../../../utils/geoIsLink';
import createNavigationLink from '../../../utils/navigationLink';

@Component({
  selector: 'app-location-list',
  imports: [TranslocoPipe],
  templateUrl: './location-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationListComponent {
  locations = input.required<ILocationModel[] | ICustomLocationModel[]>();

  createNavigationLink = createNavigationLink;
  createGeoIsLinkLink = createGeoIsLinkLink;

  openNavigationLink = (lat: number, lng: number): void => {
    window.open(createNavigationLink(lat, lng), '_blank');
  };

  getAdditionalInformation(
    location: ILocationModel | ICustomLocationModel,
  ): string | null | undefined {
    return (location as ILocationModel)
      ? (location as ILocationModel).additionalInformation
      : (location as ICustomLocationModel).description;
  }
}
