import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { type VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

export const axpoTagsVariants = cva(
  [
    'font-open-sans',
    'inline-flex',
    'justify-center',
    'items-center',
    'text-[14px]',
    'font-normal',
    'leading-4',
    'tracking-[0.17px]',
  ],
  {
    variants: {
      variant: {
        default: ['px-[14px]', 'py-[6px]', 'rounded-[10px]', 'text-text-primary'],
        role: [
          'px-[6.5px]',
          'py-[4.5px]',
          'rounded-[4px]',
          'text-text-primary',
          'font-semibold',
          'border',
          'border-solid',
          'border-text-primary',
        ],
      },
      type: {
        none: [],
        booked: ['bg-tertiary'],
        confirmed: ['bg-green-success'],
        collecting: ['bg-light-yellow'],
        cancelled: ['bg-primary'],
        completed: ['bg-text-secondary'],
        open: ['bg-light-yellow'],
        delivered: ['bg-green-success'],
        paid: ['bg-green-success'],
        operational: ['bg-green-success'],
        inoperative: ['bg-primary'],
        unavailable: ['bg-primary'],
        pending: ['bg-dark-yellow'],
      },
      generalType: {
        none: [],
        lightGreen: ['bg-tertiary'],
        green: ['bg-green-success'],
        lightYellow: ['bg-light-yellow'],
        darkYellow: ['bg-dark-yellow'],
        grey: ['bg-text-secondary'],
        coral: ['bg-primary'],
      },
      roles: {
        none: [],
        operator_admin: ['text-secondary', 'border-secondary'],
        operator: ['text-tertiary', 'border-tertiary'],
        customer_admin: ['text-medium-blue', 'border-medium-blue'],
        orderer: ['text-light-yellow', 'border-light-yellow'],
        driver: ['text-light-red', 'border-light-red'],
      },
    },
    defaultVariants: {
      variant: 'default',
      type: 'none',
      generalType: 'none',
      roles: 'none',
    },
    compoundVariants: [],
  },
);
export type AxpoTagsVariants = VariantProps<typeof axpoTagsVariants>;

@Component({
  selector: 'axpo-tag',
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './axpo-tag.component.html',
  styleUrl: './axpo-tag.component.css',
})
export class AxpoTagComponent {
  variant = input<AxpoTagsVariants['variant']>('default');
  type = input<AxpoTagsVariants['type']>('none');
  generalType = input<AxpoTagsVariants['generalType']>('none');
  roles = input<AxpoTagsVariants['roles']>('none');

  computedClass = computed(() => {
    return twMerge(
      axpoTagsVariants({
        variant: this.variant(),
        type: this.type(),
        generalType: this.generalType(),
        roles: this.roles(),
      }),
    );
  });
}
