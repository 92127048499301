import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoButtonComponent } from '../../core/axpo-button/axpo-button.component';
import { AxpoDialogComponent } from '../../core/axpo-dialog/axpo-dialog.component';
import { AxpoSearchComponent } from '../../core/axpo-search/axpo-search.component';
import { AxpoSpinnerComponent } from '../../core/axpo-spinner/axpo-spinner.component';
import {
  AxpoTableComponent,
  ITableColumn,
  ITableRow,
} from '../../core/axpo-table/axpo-table.component';
import { AxpoTypographyComponent } from '../../core/axpo-typography/axpo-typography.component';
import { IContactListModel, IContactModel } from '../../shared/models/api_models';
import { IAutocompleteEntry } from '../../shared/models/component_models';
import { ContactService } from '../../shared/services/contact.service';
import { LanguageService } from '../../shared/services/lang.service';
import { TenantService } from '../../shared/services/tenant.service';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    TranslocoPipe,
    AxpoTypographyComponent,
    AxpoSearchComponent,
    AxpoTableComponent,
    AxpoButtonComponent,
    AxpoDialogComponent,
    AxpoTypographyComponent,
    AxpoSpinnerComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './delete-contact.component.html',
})
export class DeleteContactComponent {
  translocoService = inject(TranslocoService);
  tenantService = inject(TenantService);
  languageService = inject(LanguageService);
  contactService = inject(ContactService);

  filterContactSearch = signal<string>('');
  searchQueryResult = signal<IAutocompleteEntry[]>([]);
  columns = signal<ITableColumn[]>([]);
  pageSize = signal<number>(10);
  selectedPage = signal<number>(1);
  selectedContactsToDelete = signal<ITableRow[]>([]);
  showDeleteConfirmDialog = signal<boolean>(false);

  _lang = effect(() => {
    this.languageService.getLangSignal()();

    untracked(() => {
      const translatedColumns = [
        {
          title: this.translocoService.translate('delete-contacts.tableName'),
          field: 'name',
          sortable: false,
        },
        {
          title: this.translocoService.translate('delete-contacts.tableMail'),
          field: 'mail',
          sortable: false,
        },
        {
          title: this.translocoService.translate('delete-contacts.tablePhone'),
          field: 'phone',
          sortable: false,
        },
      ];
      this.columns.set(translatedColumns);
    });
  });

  data = computed(() => {
    return (
      this.contactService.searchResult().map(importModel => {
        return this.mapToTableRow(importModel);
      }) || []
    );
  });

  buttonDisabled = computed(() => {
    return this.selectedContactsToDelete().length === 0;
  });

  mapToTableRow(importModel: IContactModel): ITableRow {
    return {
      id: { value: importModel.id ?? '??' },
      mail: { value: importModel.mail ?? '??' },
      name: { value: importModel.name ?? '??' },
      phone: { value: importModel.phone ?? '??' },
    } as ITableRow;
  }

  search() {
    const searchTerm = this.filterContactSearch();
    if (searchTerm && searchTerm.length > 2) {
      this.contactService.searchContacts(searchTerm as string, true);
      this.selectedContactsToDelete.set([]);
      this.contactService.searchResult.set([]);
    }
  }

  selectContact(entry: any) {
    const contacts = this.selectedContactsToDelete();
    const contactList = entry.row;
    const index = contacts.indexOf(contactList);
    if (index == -1) {
      contacts.push(contactList);
    } else {
      contacts.splice(index, 1);
    }

    this.selectedContactsToDelete.set(contacts);
  }

  createContactListModel(row: ITableRow): IContactListModel {
    return {
      id: Number.parseInt(row['id']?.value ?? '0'),
      name: row['name'].value,
      mail: row['mail'].value,
      phone: row['phone'].value,
    };
  }

  deleteSelectedContacts(action: string) {
    if (action === 'confirm') {
      this.showDeleteConfirmDialog.set(false);
      this.contactService.delete(
        this.selectedContactsToDelete().map(row => this.createContactListModel(row)),
      );
      this.selectedContactsToDelete.set([]);
      this.filterContactSearch.set('');
      this.contactService.searchResult.set([]);
    } else {
      this.showDeleteConfirmDialog.set(false);
    }
  }
}
