import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed,
  effect,
  inject,
  input,
  model,
  output,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import {
  AxpoFormElementComponent,
  IFormError,
} from '../../../core/axpo-form-element/axpo-form-element.component';
import {
  AxpoSelectableButtonsComponent,
  ButtonColors,
  IButton,
} from '../../../core/axpo-selectable-buttons/axpo-selectable-buttons.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { TileService } from '../../../pages/incident-detail/tile.service';
import { IIncidentModel, IKeyValueModel } from '../../models/api_models';
import { AuthnService } from '../../services/authn.service';
import { IncidentService } from '../../services/incident.service';
import { LanguageService } from '../../services/lang.service';
import { TenantService } from '../../services/tenant.service';
import { VoltageService } from '../../services/voltage.service';
import { FormValidator } from '../../utils/formValidator';
import { ITileMode } from '../../utils/tileStates';
import {
  getBackgroundColorByVoltageKey,
  getTextColorByVoltageKey,
} from '../../utils/voltageColors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  imports: [
    AxpoFormElementComponent,
    TranslocoPipe,
    AxpoSelectableButtonsComponent,
    AxpoTypographyComponent,
  ],
})
export class AlertComponent implements OnInit {
  formValidator: FormValidator | undefined;

  mode = model.required<ITileMode | undefined>();
  validationError = output<FormValidator>();
  saveCompleted = output<string>();
  activatedRoute = inject(ActivatedRoute);
  incidentService = inject(IncidentService);
  voltageService = inject(VoltageService);
  tenantService = inject(TenantService);
  translocoService = inject(TranslocoService);
  tileService = inject(TileService);
  langService = inject(LanguageService);
  private authnService = inject(AuthnService);

  isBlocked = computed(() => this.mode() !== 'edit');
  incidentModel = signal<IIncidentModel | undefined>(undefined);

  protectionReportRequired = signal<boolean | undefined>(false);
  alertErrorSource1 = signal<string | undefined>(undefined);
  alertErrorSource2 = signal<string | undefined>(undefined);
  alertErrorSource3 = signal<string | undefined>(undefined);
  alertErrorSource4 = signal<string | undefined>(undefined);
  alertMessageInfo = signal<string | undefined>(undefined);
  alertName = signal<string | undefined>(undefined);
  alertRelayData1 = signal<string | undefined>(undefined);
  alertRelayData2 = signal<string | undefined>(undefined);
  alertRelayData3 = signal<string | undefined>(undefined);
  alertRelayData4 = signal<string | undefined>(undefined);
  voltages = signal<IKeyValueModel[] | undefined>(undefined);
  buttons = signal<IButton[]>([]);
  selectedVoltages = signal<(string | number)[]>([]);
  saveTrigger = input<string | undefined>(undefined);

  getTextColorByVoltageKey = getTextColorByVoltageKey;
  getBackgroundColorByVoltageKey = getBackgroundColorByVoltageKey;

  ngOnInit(): void {
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    if (accessCode && this.isLoggedIn()) {
      this.voltageService.getVoltages(accessCode);
    }
  }

  _ = effect(() => {
    if (!this.formValidator) {
      this.formValidator = new FormValidator(
        [
          {
            key: 'alertName',
            errorMessageSignal: signal(undefined),
            errorIdSignal: signal(undefined),
          },
          {
            key: 'voltage',
            errorMessageSignal: signal(undefined),
            errorIdSignal: signal(undefined),
          },
        ],
        this.translocoService,
      );
    }
  });

  _updateIncidentDetails = effect(() => {
    const details = this.incidentService.incidentDetails();
    const allVoltages = this.voltageService.voltages();
    const buttons = allVoltages?.map(x => {
      const selected = (details?.voltages?.filter(v => v.key == x.key).length ?? -1) > 0;

      return {
        id: x.value,
        value: x.key ? parseInt(x.key) : -1,
        selected: selected,
        title: x.value ?? 'Error',
        colors: x.key as ButtonColors,
      };
    });

    untracked(() => {
      this.incidentModel.set(details);

      this.alertName.set(details?.alertName);
      this.alertMessageInfo.set(details?.alertMessageInfo);
      this.alertErrorSource1.set(details?.alertErrorSource1);
      this.alertErrorSource2.set(details?.alertErrorSource2);
      this.alertErrorSource3.set(details?.alertErrorSource3);
      this.alertErrorSource4.set(details?.alertErrorSource4);
      this.alertRelayData1.set(details?.alertRelayData1);
      this.alertRelayData2.set(details?.alertRelayData2);
      this.alertRelayData3.set(details?.alertRelayData3);
      this.alertRelayData4.set(details?.alertRelayData4);
      this.protectionReportRequired.set(details?.protectionReportRequired);
      this.voltages.set(details?.voltages);

      this.buttons.set(buttons ?? []);

      this.selectedVoltages.set(
        details?.voltages && details?.voltages.length > 0
          ? (details.voltages.map(voltage => (voltage.key ? voltage.key : '')) ?? [])
          : [],
      );

      this.checkRequired('alertName', this.alertName());
      this.checkRequired('voltage', this.selectedVoltages().join());
      if (!this.formValidator?.getField('voltage')?.errorMessage()) {
        this.checkMaxVoltages(this.selectedVoltages().join(), 2);
      }
    });
  });

  _triggerSave = effect(() => {
    const save = this.tileService.alertChangedTimestamp();
    untracked(() => {
      if (save && this.tileService.saved().indexOf(save) === -1) {
        this.tileService.saved().push(save);
        this.onSave('confirm');
      }
    });
  });

  _parentTriggersSave = effect(() => {
    const save = this.saveTrigger();
    if (!this.formValidator?.isValid && save) {
      this.formValidator?.showErrors();
      this.saveCompleted.emit('-1');
      return;
    }
    if (save) {
      this.onSave('confirm', true);
    }
  });

  _alerNameValidationEffect = effect(() => {
    const alertName = this.alertName();
    this.langService.getLangSignal()();

    untracked(() => {
      this.checkRequired('alertName', alertName);
    });
  });

  _voltageValidationEffect = effect(() => {
    const selectedVoltage = this.selectedVoltages();
    this.langService.getLangSignal()();

    untracked(() => {
      this.checkRequired('voltage', selectedVoltage.join());
      if (!this.formValidator?.getField('voltage')?.errorMessage()) {
        this.checkMaxVoltages(this.selectedVoltages().join(), 2);
      }
    });
  });

  _formValidationChanged = effect(() => {
    const validator = this.formValidator;
    if (validator) {
      const validationUpdated = validator.showErrorOnLoad();
      if (validationUpdated) {
        this.validationError.emit(validator);
      }
    }
  });

  isLoggedIn = computed(() => {
    return !!this.authnService.user();
  });

  onSave(action: 'confirm' | 'cancel', skipTenantId?: boolean) {
    const incidentDetails = this.incidentService.incidentDetails();

    const apiData = { ...incidentDetails };
    apiData.alertErrorSource1 = this.alertErrorSource1();
    apiData.alertErrorSource2 = this.alertErrorSource2();
    apiData.alertErrorSource3 = this.alertErrorSource3();
    apiData.alertErrorSource4 = this.alertErrorSource4();

    apiData.alertName = this.alertName();
    apiData.alertMessageInfo = this.alertMessageInfo();

    apiData.alertRelayData1 = this.alertRelayData1();
    apiData.alertRelayData2 = this.alertRelayData2();
    apiData.alertRelayData3 = this.alertRelayData3();
    apiData.alertRelayData4 = this.alertRelayData4();
    apiData.voltages = this.voltages();
    apiData.protectionReportRequired = this.protectionReportRequired();

    if (this.mode() === 'create' && !apiData.incidentStateId) {
      apiData.incidentStateId = 1;
    }

    const accessCode = this.incidentService.incidentDetails()?.accessCode;
    const tenantId = !skipTenantId ? this.tenantService.tenantId() : undefined;

    if (action === 'confirm' && accessCode) {
      const allVoltages = this.voltageService.voltages();
      for (const voltage of allVoltages ?? []) {
        const isVoltageCurrentlySelected =
          this.selectedVoltages().filter(v => v.toString() == voltage.key).length > 0;
        const isVoltageCurrnetlySavedinIncident =
          (incidentDetails?.voltages ?? []).filter(v => v.key?.toString() == voltage.key).length >
          0;

        if (!isVoltageCurrentlySelected && isVoltageCurrnetlySavedinIncident) {
          this.voltageService.removeVoltage(accessCode, parseInt(voltage.key ?? ''));
        }
        if (isVoltageCurrentlySelected && !isVoltageCurrnetlySavedinIncident) {
          this.voltageService.addVoltage(accessCode, parseInt(voltage.key?.toString() ?? ''));
        }
      }

      this.incidentService.update(accessCode, tenantId, apiData);
    }

    this.saveCompleted.emit(Date.now().toString());
  }

  voltageSelected(key: string) {
    this.selectedVoltages.set(key.split(','));
    this.voltages.set(this.voltageService.voltages()?.filter((voltage: IKeyValueModel) => {
      if (voltage.key && this.selectedVoltages().indexOf(voltage.key) > -1) {
        return true;
      }
      
      return false;
    }));
  }

  checkRequired = (field: string, value: string | undefined) => {
    if (this.incidentService.incidentDetails() && !value) {
      this.formValidator?.setError(
        field,
        -1,
        this.translocoService.translate('validation.required'),
      );
    } else {
      const currentError = this.formValidator?.getField(field);
      if (currentError?.errorId() === -1) {
        this.formValidator?.clearError(field);
      }
    }

    if (this.formValidator) {
      this.validationError.emit(this.formValidator);
    }
  };

  checkMaxVoltages = (value: string | undefined, maxEntries: number) => {
    if (this.incidentService.incidentDetails() && value) {
      const count = value?.split(',').length;
      if (count && count > maxEntries) {
        this.formValidator?.setError(
          'voltage',
          -1,
          this.translocoService.translate('incidentDetail.panelAlert.maxVoltages'),
        );
        this.formValidator?.showErrors();
      }
    } else {
      const currentError = this.formValidator?.getField('voltage');
      if (currentError?.errorId() === -1) {
        this.formValidator?.clearError('voltage');
      }
    }

    if (this.formValidator) {
      this.validationError.emit(this.formValidator);
    }
  };

  formValidation(formError: IFormError | undefined, key: string) {
    if (formError) {
      this.formValidator?.setError(
        formError.formId,
        formError.value,
        formError.message,
        formError.replacement,
      );
    } else {
      this.formValidator?.clearError(key);
    }
    if (this.formValidator) {
      this.validationError.emit(this.formValidator);
    }
  }
}
