import {
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  output,
  signal,
  untracked,
} from '@angular/core';

@Component({
  selector: 'app-timer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './timer.component.html',
})
export class TimerComponent {
  timerSeconds = input.required<number>();
  timeRemainingUntilRefresh = signal<number>(60);
  timer = signal<NodeJS.Timeout | undefined>(undefined);
  startTimerClass = signal<string>('fa-stopwatch');
  runningTimerHover = signal<boolean>(false);
  timerElapsed = output();

  init = effect(() => {
    const ts = this.timerSeconds();
    untracked(() => {
      this.timeRemainingUntilRefresh.set(ts);
    });
  });

  startTimer() {
    const initialOffset = -110;

    const timer = setInterval(() => {
      this.timeRemainingUntilRefresh.set(this.timeRemainingUntilRefresh() - 1);

      if (this.timeRemainingUntilRefresh() <= 0) {
        this.timerElapsed.emit();
        this.timeRemainingUntilRefresh.set(this.timerSeconds());
      }

      // animation
      const strokeDashoffset =
        initialOffset - this.timeRemainingUntilRefresh() * (initialOffset / this.timerSeconds());
      this.strokeDashoffset.set(strokeDashoffset.toString());
    }, 1000);

    this.timer.set(timer);
  }

  strokeDashoffset = signal<string>('');

  stopTimer() {
    if (this.timer()) {
      clearInterval(this.timer());
      this.timeRemainingUntilRefresh.set(this.timerSeconds());
      this.timer.set(undefined);
    }
  }
  toggleStartTimer() {
    if (this.startTimerClass() == 'fa-stopwatch') {
      this.startTimerClass.set('fa-play');
    } else {
      this.startTimerClass.set('fa-stopwatch');
    }
  }

  toggleRunningTimer(hover: boolean) {
    this.runningTimerHover.set(hover);
  }
}
