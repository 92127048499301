<div class="flex flex-col items-stretch min-h-screen">
  <axpo-header
    [title]="'core.incidentManagement' | transloco"
    [breadcrumbDeactivated]="true"
    [user]="this.authnService.user()"
    [availableLanguages]="availableLanguages"
    [(selectedLanguageCode)]="activeLanguage"
    [isOffline]="offlineService.offline()"
    (logout)="logout()"
    [showLogout]="showLogout()"
    [customActions]="customActions()"
  />

  <main class="grow shrink-0 text-text-primary">
    <router-outlet />
  </main>
  <axpo-footer [footerLinks]="footerLinks()" [version]="version" />
</div>
