<section class="bg-white dark:bg-gray-900">
  <div class="max-w-screen-md px-4 py-8 mx-auto lg:py-16 lg:px-6">
    @if (!tenantService.isLoadingTenants() && tenants().length > 1) {
      <axpo-typography [variant]="'H4'">
        {{ 'admin.tenantAdministration' | transloco }}
      </axpo-typography>
      <div class="py-8 pr-0 sm:w-1/2 sm:pr-4">
        <axpo-form-element
          formId="tenant"
          formType="select"
          [(value)]="selectedTenant"
          [options]="tenants()"
          [required]="true"
          (errorEmitter)="formValidation($event, 'tenant')"
          [errorMessageInput]="formValidator?.getField('tenant')?.errorMessage()"
        ></axpo-form-element>
      </div>
    }

    @if (tenantService.isLoadingTenants() || tenantService.isLoadingSettings()) {
      <div class="flex justify-center items-center p-12">
        <axpo-spinner size="xl">{{ 'core.loading' | transloco }}</axpo-spinner>
      </div>
    }

    @if (!tenantService.isLoadingSettings() && tenantService.tenantSettings()) {
      <axpo-typography [variant]="'title-xl'">
        {{ 'admin.mainSettings' | transloco }}
      </axpo-typography>

      <div>
        <div class="pb-4">
          <div class="pb-2">
            <axpo-typography [variant]="'title-l'">
              {{ 'admin.application' | transloco }}
            </axpo-typography>
          </div>
          <div class="pb-3 pr-0 sm:w-1/2 sm:pr-4">
            <axpo-form-element
              formId="applicationName"
              formType="text"
              [label]="'admin.applicationName' | transloco"
              [max]="50"
              [(value)]="appName"
              [errorMessageInput]="formValidator?.getField('applicationName')?.errorMessage()"
            />
          </div>
        </div>

        <div class="pb-4">
          <div class="pb-2">
            <axpo-typography [variant]="'title-l'">
              {{ 'admin.tenantAndContact' | transloco }}
            </axpo-typography>
          </div>
          <div class="flex flex-col space-x-0 sm:flex-row sm:space-x-8">
            <div class="grow">
              <div class="pb-3">
                <axpo-form-element
                  formId="contactName"
                  formType="text"
                  [label]="'admin.firstAndLastName' | transloco"
                  [(value)]="contactName"
                  [max]="100"
                  [errorMessageInput]="formValidator?.getField('contactName')?.errorMessage()"
                />
              </div>
            </div>
            <div class="grow">
              <div class="pb-3">
                <axpo-form-element
                  formId="contactEmail"
                  formType="text"
                  [label]="'admin.email' | transloco"
                  [max]="100"
                  [(value)]="contactEmail"
                  [errorMessageInput]="formValidator?.getField('contactEmail')?.errorMessage()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pb-4">
        @if (this.tenantService.tenantSettings()?.tileModels) {
          <axpo-typography [variant]="'title-l'">
            {{ 'admin.panelSettings' | transloco }}
          </axpo-typography>
        }
        <div class="pr-0 sm:w-4/5 sm:pr-4">
          @for (tile of this.tenantService.tenantSettings()?.tileModels; track tile.name) {
            <div class="flex justify-between items-center py-0.5">
              <axpo-typography [variant]="'body-m'">
                {{ 'admin.' + tile.name | transloco }}
              </axpo-typography>
              <axpo-selectable-buttons
                [disabled]="isSettingDisabled(tile.name)"
                [buttons]="[
                  {
                    id: tile.name + '1',
                    title: 'admin.off' | transloco,
                    value: 1,
                    selected: !tile.isVisible,
                    colors: undefined,
                  },
                  {
                    id: tile.name + '2',
                    title: 'admin.on' | transloco,
                    value: 2,
                    selected: !!tile.isVisible,
                    colors: undefined,
                  },
                ]"
                [multiselect]="false"
                (buttonClicked)="onTileSettingClicked($event, tile.name)"
              />
            </div>
          }
        </div>
      </div>

      <div class="pb-4">
        <div class="pb-2">
          <axpo-typography [variant]="'title-l'">
            {{ 'admin.mailingList' | transloco }}
          </axpo-typography>
        </div>
        <div class="pr-0 sm:w-1/2 sm:pr-4">
          <app-mail-search-dropdown
            [defaultItem]="tenantService.tenantSettings()?.tenantIncidentMailRecipientContact"
            [contacts]="detailsService.contactSearch()"
            (searchItemsFilter)="searchContactsFilter($event)"
            (selectItem)="onSetContact($event)"
            (onRemoveCurrentItem)="selectedContact.set(undefined)"
          ></app-mail-search-dropdown>
        </div>
      </div>

      <div class="pb-4">
        <div class="pb-2">
          <axpo-typography [variant]="'title-l'">
            {{ 'admin.mailingListSupplyFailure' | transloco }}
          </axpo-typography>
        </div>
        <div class="sm:w-1/2 pr-0 sm:pr-4">
          <app-mail-search-dropdown
            [defaultItem]="tenantService.tenantSettings()?.tenantSupplyFailureMailRecipientContact"
            [contacts]="detailsService.contactSearch()"
            (searchItemsFilter)="searchContactsFilter($event)"
            (selectItem)="onSetSupplyFailureContact($event)"
            (onRemoveCurrentItem)="selectedSupplyFailureContact.set(undefined)"
          ></app-mail-search-dropdown>
        </div>
      </div>

      <div class="flex py-8 space-x-0 justify-evenly sm:space-x-8 sm:justify-end">
        <axpo-button (click)="onBackClicked()" [type]="'outlined'" [style]="'secondary'">
          {{ 'admin.back' | transloco }}
        </axpo-button>
        <axpo-button (click)="onSubmitClicked()" [style]="'primary'" [disabled]="!isFormValid()">
          {{ 'admin.submit' | transloco }}
        </axpo-button>
      </div>
    }
  </div>
</section>
