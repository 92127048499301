import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  inject,
  input,
  output,
  viewChild,
} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoTypographyComponent } from '../../../../core/axpo-typography/axpo-typography.component';
import { LanguageService } from '../../../services/lang.service';

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-filedrop',
  templateUrl: './filedrop.component.html',
  imports: [TranslocoPipe, FontAwesomeModule, AxpoTypographyComponent],
})
export class FileDropComponent implements OnInit, OnDestroy {
  translocoService = inject(TranslocoService);
  langService = inject(LanguageService);
  accept = input.required<string>();
  acceptedFileTypesLabel = input.required<string>();
  multiple = input.required<boolean>();
  acceptCamera = input.required<boolean>();
  filesUploaded = output<FileList>();
  upload = viewChild<ElementRef>('upload');
  dragover = false;

  // We need a reference to the arrow function
  // This allows us to add and remove the eventlistener
  pasteEventReference = (event: ClipboardEvent) => this.handlePasteEvent(event);

  ngOnInit(): void {
    // Listen to global event "paste" (ctrl+v)
    window.addEventListener('paste', this.pasteEventReference);
  }

  ngOnDestroy(): void {
    // Important to remove the global event listeners!
    window.removeEventListener('paste', this.pasteEventReference);
  }

  // Allowing to paste directly an file/screenshot from the clipboard
  handlePasteEvent(event: ClipboardEvent): void {
    // We can simply return the clipboardData.files
    if (event.clipboardData?.files.length) {
      this.filesSelected(event.clipboardData?.files);
    } else {
      alert('Components_FileDrop_PasteEvent_Failed');
    }
  }

  filesSelected(fileList: FileList) {
    this.dragover = false;
    this.filesUploaded.emit(fileList);
    return fileList;
  }

  changeEvent(e: Event) {
    const target = e.target as HTMLInputElement;
    if (target.files) {
      this.filesSelected(target.files);
      this.clearFiles();
    }
  }

  dropEvent(dragevent: DragEvent) {
    if (dragevent.dataTransfer) {
      this.filesSelected(dragevent.dataTransfer.files);
    }
  }

  uploadClick() {
    if (this.upload()) {
      this.upload()?.nativeElement.click();
    }
  }

  clearFiles() {
    (this.upload()?.nativeElement as HTMLInputElement).value = '';
  }
}
