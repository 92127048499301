import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  WritableSignal,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoFooterComponent, FooterLinksProps } from './core/axpo-footer/axpo-footer.component';
import { AxpoHeaderComponent } from './core/axpo-header/axpo-header.component';
import { ICustomAction } from './core/axpo-profile-dropdown/axpo-profile-dropdown.component';
import { AuthnService } from './shared/services/authn.service';
import { BreadcrumbService } from './shared/services/breadcrumb.service';
import { version } from '../../public/version';
import { OfflineService } from './shared/services/offline.service';
import { TenantService } from './shared/services/tenant.service';
import { isSuperAdmin } from './shared/utils/authValidations';

export interface ILanguage {
  label: string;
  value: string;
}

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, AxpoHeaderComponent, AxpoFooterComponent, TranslocoPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  private translocoService = inject(TranslocoService);
  private breadcrumbService = inject(BreadcrumbService);
  private tenantService = inject(TenantService);
  public offlineService = inject(OfflineService);
  public readonly authnService = inject(AuthnService);

  activeLanguage = signal<string | undefined>(undefined);
  footerLinks = signal<FooterLinksProps[]>([]);
  showLogout = signal<boolean>(false);
  customActions = signal<ICustomAction[]>([]);

  version = version;
  availableLanguages: ILanguage[] = [
    { label: 'English', value: 'en' },
    { label: 'Deutsch', value: 'de' },
  ];

  _activeLangueEffect = effect(() => {
    const activeLanguage = this.activeLanguage();
    untracked(() => {
      if (activeLanguage) {
        this.translocoService.setActiveLang(activeLanguage);
        localStorage.setItem('language', activeLanguage);
        this.getFooterLinks();
        this.getBreadcrumbs();
      }
    });
  });

  _tenantTriggerEffect = effect(() => {
    const user = this.authnService.user();
    untracked(() => {
      if (user) {
        this.tenantService.getConfig();
        this.showLogout.set(true);
      }
    });
  });

  _userEffect = effect(() => {
    const user = this.authnService.user();
    const _ = this.activeLanguage();
    untracked(() => {
      if (user) {
        if (isSuperAdmin(this.authnService.user()?.roles)) {
          this.customActions.set([
            {
              label: this.translocoService.translate('core.tenantAdministration'),
              icon: 'gear',
              link: '/tenant-administration',
            },
            {
              label: this.translocoService.translate('core.importLocation'),
              icon: 'gear',
              link: '/import-locations',
            },
            {
              label: this.translocoService.translate('core.importContact'),
              icon: 'gear',
              link: '/import-contacts',
            },
            {
              label: this.translocoService.translate('core.deleteContacts'),
              icon: 'gear',
              link: '/delete-contacts',
            },
            {
              label: this.translocoService.translate('core.statistics'),
              icon: 'gear',
              link: '/statistic/chart',
            },
          ]);
        }
      }
    });
  });

  ngOnInit() {
    this.loadLanguage();
    this.authnService.checkAuth();
    this.offlineService.checkOffline();
  }

  loadLanguage = () => {
    const cachedLanguage = localStorage.getItem('language');
    if (cachedLanguage) {
      this.activeLanguage.set(cachedLanguage);
    } else {
      const defaultLanguage = this.translocoService.getActiveLang();
      this.activeLanguage.set(defaultLanguage);
    }
  };

  getFooterLinks = () => {
    const links: FooterLinksProps[] = [
      {
        text: this.translocoService.translate('core.imprint'),
        link: 'https://www.axpo.com/group/' + this.activeLanguage() + '/impressum.html',
        target: 'external_link',
      },
      {
        text: this.translocoService.translate('core.legalInformation'),
        link: 'https://www.axpo.com/group/' + this.activeLanguage() + '/legal-information.html',
        target: 'external_link',
      },
      {
        text: this.translocoService.translate('core.privacyPolicy'),
        link: 'https://www.axpo.com/group/' + this.activeLanguage() + '/privacy-policy.html',
        target: 'external_link',
      },
    ];
    this.footerLinks.set(links);
  };

  getBreadcrumbs = () => {
    const translatedBreadcrumb = {
      Home: this.translocoService.translate('breadcrumbs.home'),
      Unauthorized: this.translocoService.translate('breadcrumbs.unauthorized'),
      Login: this.translocoService.translate('breadcrumbs.login'),
    };
    this.breadcrumbService.replaceBreadcrumbs(translatedBreadcrumb);
  };

  logout = () => {
    this.authnService.logout();
  };

  isOffline = (): WritableSignal<boolean> => {
    return this.offlineService.offline;
  };
}
