import { Route } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';

import { AdminComponent } from './pages/admin/admin.component';
import { DeleteContactComponent } from './pages/delete-contact/delete-contact.component';
import { HomeComponent } from './pages/home/home.component';
import { ImportContactComponent } from './pages/import-contact/import-contact.component';
import { ImportLocationComponent } from './pages/import-location/import-location.component';
import { IncidentDetailComponent } from './pages/incident-detail/incident-detail.component';
import { IncidentWizardComponent } from './pages/incident-wizard/incident-wizard.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { StatisticsComponent } from './pages/statistics/statistics.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { RoleGuard } from './shared/guards/role.guard';
import { Roles } from './shared/utils/constants';

export const ROUTES: Route[] = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  // { path: 'login', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    component: HomeComponent,
    title: 'IMA',
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'tenant-administration',
    component: AdminComponent,
    title: 'IMA',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: [Roles.SUPER_ADMIN] },
  },
  {
    path: 'import-locations',
    component: ImportLocationComponent,
    title: 'IMA',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: [Roles.SUPER_ADMIN] },
  },
  {
    path: 'delete-contacts',
    component: DeleteContactComponent,
    title: 'IMA',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: [Roles.SUPER_ADMIN] },
  },
  {
    path: 'import-contacts',
    component: ImportContactComponent,
    title: 'IMA',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: [Roles.SUPER_ADMIN] },
  },
  {
    path: 'login',
    component: LoginComponent,
    title: 'IMA',
  },
  {
    path: 'incident/:accessCode/:tenantId',
    component: IncidentDetailComponent,
    title: 'IMA',
  },
  {
    path: 'incident/:accessCode/edit/:step',
    component: IncidentWizardComponent,
    title: 'IMA',
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'statistic/:tab',
    component: StatisticsComponent,
    title: 'IMA',
    canActivate: [AutoLoginPartialRoutesGuard, RoleGuard],
    data: { roles: [Roles.SUPER_ADMIN] }
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    title: 'IMA - Unauthorized Access',
  },
  {
    path: '404',
    component: NotFoundComponent,
    title: 'IMA - Not Found',
  },
  {
    path: '**',
    component: NotFoundComponent,
    title: 'IMA - Not Found',
  },
];
