import { Injectable, inject, signal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from './api.service';
import { ICommentModel, IValidationErrorModel } from '../models/api_models';
import { getErrorMessage } from '../utils/errorHandling';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  private apiService = inject(ApiService);
  private toastr = inject(ToastrService);
  private translocoService = inject(TranslocoService);

  isUpdatingComment = signal<boolean>(false);
  commentUpdateResult = signal<ICommentModel | undefined>(undefined);
  commentCreateResult = signal<ICommentModel | undefined>(undefined);
  commentDeleteResult = signal<number | undefined>(undefined);

  addOrUpdate(accessCode: string, tenantId: string, comment: ICommentModel) {
    this.isUpdatingComment.set(true);
    this.apiService
      .post<number>(`/api/v1/Comment/add-or-update/${accessCode}/${tenantId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: comment,
      })
      .subscribe({
        next: details => {
          if (comment.id) {
            this.commentUpdateResult.set(comment);
          } else {
            this.commentCreateResult.set({ ...comment, id: details });
          }
        },
        error: (error: IValidationErrorModel) => {
          this.toastr.error(getErrorMessage(error, this.translocoService, 'incidentDetail.comments.invalid', 'Updating/Adding comment failed'));
        },
        complete: () => {
          this.isUpdatingComment.set(false);
        },
      });
  }

  delete(accessCode: string, id: string) {
    this.isUpdatingComment.set(true);
    this.apiService.delete<any>(`/api/v1/Comment/delete/${accessCode}/${id}`).subscribe({
      next: details => {
        this.commentDeleteResult.set(details);
      },
      error: (error: IValidationErrorModel) => {
        this.toastr.error(getErrorMessage(error, this.translocoService, 'incidentDetail.comments.invalid', 'Deleting comment failed'));
      },
      complete: () => {
        this.isUpdatingComment.set(false);
      },
    });
  }
}
