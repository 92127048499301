import { TranslocoService } from '@jsverse/transloco';

import { IKeyValueModel } from '../models/api_models';

export const getTitle = (
  translocoService: TranslocoService,
  voltages?: IKeyValueModel[],
  alertName?: string,
  impact?: IKeyValueModel,
  withSupplyFailure?: boolean
) => {
  const joinable = [];
  if (voltages) {
    const voltageString = voltages.map(vol => vol.value).join('/');
    joinable.push(voltageString);
  }
  if (alertName) {
    joinable.push(alertName);
  }

  if (impact) {
    joinable.push(translocoService.translate('incidentDetail.' + impact.value).toLowerCase());
  }

  const joinableString = joinable.join(' - ');
  if (withSupplyFailure) {
    return joinableString + ' ' + translocoService.translate('incidentDetail.faults').toLowerCase();
  }

  return joinableString;
};
