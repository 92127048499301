import { TranslocoService } from "@jsverse/transloco";

import { IValidationErrorModel } from "../models/api_models";

export const getErrorMessage = (errorResponse: any, translocoService: TranslocoService, translationKey: string, fallbackMsg: string) => {
  const errors = Array.isArray(errorResponse.error)
    ? (errorResponse.error as IValidationErrorModel[])
    : [errorResponse.error];
  const msg = errors.map(error =>
    error.key
      ? translocoService.translate(translationKey + error.key)
      : error.errorMessage,
  );
  return (msg.length > 0 ? String(msg) : fallbackMsg);
}