import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import {
  FileResponse,
  ILocationImportResultModel,
} from '../models/api_models';
import { getErrorMessage } from '../utils/errorHandling';

@Injectable({
  providedIn: 'root',
})
export class LocationImportService {
  environment = environment;
  httpService = inject(HttpClient);
  private apiService = inject(ApiService);
  private translocoService = inject(TranslocoService);
  private toastr = inject(ToastrService);

  Files = signal<FileResponse[] | undefined>(undefined);
  isUploadingLocationImportFile = signal<boolean>(false);

  validationDetails = signal<ILocationImportResultModel | undefined>(undefined);

  upload(file: File, persist: boolean) {
    const formData = new FormData();
    if (file !== null && file !== undefined) formData.append('file', new Blob([file]), file.name); // todo: this causes
    const url = this.environment.apiUrl + `/api/v1/Location/import-file/${persist}`;

    this.isUploadingLocationImportFile.set(true);
    this.validationDetails.set(undefined);
    const req = new HttpRequest('POST', url, formData);

    this.httpService.request(req).subscribe({
      next: (details: any) => {
        this.validationDetails.set(details.body as ILocationImportResultModel);
      },
      error: (errorResponse: any) => {
        this.toastr.error(getErrorMessage(errorResponse, this.translocoService, 'incidentDetail.panelAttachments.invalid', 'Location upload from API failed'));
      },
      complete: () => {
        this.isUploadingLocationImportFile.set(false);
      },
    });
  }

  uploadFromApi(persist: boolean) {
    this.isUploadingLocationImportFile.set(true);
    this.validationDetails.set(undefined);
    this.apiService.post<string>(`/api/v1/Location/import-api/${persist}`).subscribe({
      next: (details: any) => {
        this.validationDetails.set(details.body as ILocationImportResultModel);
      },
      error: (errorResponse: any) => {
        this.toastr.error(getErrorMessage(errorResponse, this.translocoService, 'incidentDetail.panelAttachments.invalid', 'Location upload from API failed'));
      },
      complete: () => {
        this.isUploadingLocationImportFile.set(false);
      },
    });
  }

  TransformValidationMessagesToGenreicStrings(message: string) {
    const invalidCoordinatesKey = 'invalidCoordinates';
    const invalidCoordinatesMessage1 = 'Der Datensatz mit Id ';
    const invalidCoordinatesMessage2 = ' hat ungültige Koordinaten und wird deshalb ignoriert.';

    const invalidDataLengthKey = 'invalidDataLength';
    const invalidDataLengthMessage1 = 'Der Datensatz auf Zeile ';
    const invalidDataLengthMessage2 =
      ' hat nicht die erwartete Anzahl Trennzeichen und wird deshalb ignoriert.';

    const invalidLocationTypeNameKey = 'invalidLocationTypeName';
    const invalidLocationTypeNameMessage1 = 'Der Datensatz mit Id ';
    const invalidLocationTypeNameMessage2 =
      ' hat einen ungültigen Standorttyp und wird deshalb ignoriert.';

    const missingAxpoIdNameKey = 'missingAxpoId';
    const missingAxpoIdNameMessage1 = 'Datensatz auf Zeile ';
    const missingAxpoIdNameMessage2 = ' hat keine Id.';

    const validationFailedKey = 'validationFailed';
    const validationFailedMessage1 = 'Der Datensatz mit Id ';
    const validationFailedMessage2 = ' hat ungültige Werte und wird deshalb ignoriert (';
    const validationFailedMessage3 = ').';

    if (message.indexOf(invalidCoordinatesMessage2) > 0) {
      message = message.replace(invalidCoordinatesMessage1, '');
      message = message.replace(invalidCoordinatesMessage2, '');
      return this.translocoService.translate('import-location.' + invalidCoordinatesKey, {
        value: message,
      });
    } else if (message.indexOf(invalidDataLengthMessage2) > 0) {
      message = message.replace(invalidDataLengthMessage1, '');
      message = message.replace(invalidDataLengthMessage2, '');
      return this.translocoService.translate('import-location.' + invalidDataLengthKey, {
        value: message,
      });
    } else if (message.indexOf(invalidLocationTypeNameMessage2) > 0) {
      message = message.replace(invalidLocationTypeNameMessage1, '');
      message = message.replace(invalidLocationTypeNameMessage2, '');
      return this.translocoService.translate('import-location.' + invalidLocationTypeNameKey, {
        value: message,
      });
    } else if (message.indexOf(missingAxpoIdNameMessage2) > 0) {
      message = message.replace(missingAxpoIdNameMessage1, '');
      message = message.replace(missingAxpoIdNameMessage2, '');
      return this.translocoService.translate('import-location.' + missingAxpoIdNameKey, {
        value: message,
      });
    } else if (message.indexOf(validationFailedMessage2) > 0) {
      const index1 = message.indexOf(validationFailedMessage2);
      const index2 = message.indexOf(validationFailedMessage3);
      const value1 = message.substring(validationFailedMessage1.length, index1);
      let value2 = message.substring(index1 + validationFailedMessage2.length, index2);

      if (value2.indexOf('Hier müssen Positionsdaten angegeben werden.') > -1) {
        value2 = this.translocoService.translate(
          'import-location.customLocationModel.invalidCoordinates',
        );
      } else if (
        value2.indexOf('Hier muss ein Text mit maximal 100 Zeichen angegeben werden.') > -1
      ) {
        value2 = this.translocoService.translate(
          'import-location.customLocationModel.invalidDescription',
        );
      } else if (
        value2.indexOf('Hier muss ein Text mit 1 bis 100 Zeichen angegeben werden.') > -1
      ) {
        value2 = this.translocoService.translate('import-location.customLocationModel.invalidName');
      }

      return this.translocoService.translate('import-location.' + validationFailedKey, {
        value: value1,
        value2: value2,
      });
    }

    return message;
  }
}
