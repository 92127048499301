@if (contactService.isLoadingSearch()) {
  <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="p-8 bg-white rounded shadow-lg">
      <axpo-spinner size="normal">{{ 'core.loading' | transloco }}</axpo-spinner>
    </div>
  </div>
}
@if (showDeleteConfirmDialog()) {
  <axpo-dialog
    title="{{ 'delete-contacts.deleteContactDialogTitle' | transloco }}"
    cancelText="{{ 'core.cancel' | transloco }}"
    confirmText="{{ 'delete-contacts.deleteSelectedContacts' | transloco }}"
    textAlign="left"
    [processBeforeClose]="true"
    [loadingStateText]="'core.loading' | transloco"
    [dataLoaded]="true"
    (takenAction)="deleteSelectedContacts($event)"
    [saveDisabled]="false"
  >
    <div class="flex flex-col space-y-4">
      <axpo-typography [variant]="'body-m'">
        {{ 'delete-contacts.deleteContactDialogText' | transloco }}
      </axpo-typography>
    </div>
  </axpo-dialog>
}
<section class="bg-white dark:bg-gray-900">
  <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
    <div class="mb-4">
      <axpo-typography [variant]="'H4'">
        {{ 'delete-contacts.title' | transloco }}
      </axpo-typography>
    </div>
    <div class="flex flex-col justify-center gap-8 mt-8 mb-8 sm:flex-row">
      <div class="flex-1 max-w-80">
        <axpo-search
          [(searchValue)]="filterContactSearch"
          [debounceTime]="500"
          [debounce]="true"
          (keyup.enter)="search()"
        ></axpo-search>
      </div>
      <div>
        <axpo-button (click)="search()">
          {{ 'delete-contacts.search' | transloco }}
        </axpo-button>
      </div>
    </div>
    <axpo-table
      [columns]="columns()"
      [data]="data()"
      [actions]="['select']"
      (actionClick)="selectContact($event)"
    ></axpo-table>

    <div class="pt-4">
      <axpo-button
        (click)="showDeleteConfirmDialog.set(true)"
        [classList]=""
        [disabled]="this.selectedContactsToDelete().length === 0"
      >
        {{ 'delete-contacts.deleteSelectedContacts' | transloco }}
      </axpo-button>
    </div>
  </div>
</section>
