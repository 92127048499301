import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  output,
  signal,
  untracked,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';

import { AxpoDialogComponent } from '../../../core/axpo-dialog/axpo-dialog.component';
import {
  AxpoFormElementComponent,
  IFormError,
  IOption,
} from '../../../core/axpo-form-element/axpo-form-element.component';
import {
  AxpoSelectableButtonsComponent,
  IButton,
} from '../../../core/axpo-selectable-buttons/axpo-selectable-buttons.component';
import { AxpoTypographyComponent } from '../../../core/axpo-typography/axpo-typography.component';
import { TileService } from '../../../pages/incident-detail/tile.service';
import { IIncidentDetailsUpdateModel, IIncidentUpdateModel } from '../../models/api_models';
import { DataFragments, DetailsService } from '../../services/details.service';
import { IncidentService } from '../../services/incident.service';
import { LanguageService } from '../../services/lang.service';
import { TenantService } from '../../services/tenant.service';
import { addTimeToDate, dateToDay, isoDateToTime } from '../../utils/date';
import { FormValidator } from '../../utils/formValidator';
import { getTitle } from '../../utils/incident';
import { ITileMode } from '../../utils/tileStates';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-edit-details',
  templateUrl: './edit-details.component.html',
  imports: [
    AxpoFormElementComponent,
    AxpoSelectableButtonsComponent,
    TranslocoPipe,
    AxpoTypographyComponent,
    AxpoDialogComponent,
  ],
})
export class EditDetailsComponent {
  mode = input.required<ITileMode>();
  tileService = inject(TileService);
  activatedRoute = inject(ActivatedRoute);
  detailsService = inject(DetailsService);
  incidentService = inject(IncidentService);
  langService = inject(LanguageService);
  tenantService = inject(TenantService);
  translocoService = inject(TranslocoService);
  isBlocked = computed(() => this.mode() !== 'edit' && this.mode() !== 'create');

  categoryId = signal<number | undefined>(undefined);
  categoryName = signal<string | undefined>(undefined);
  creatorName = signal<string | undefined>(undefined);
  description = signal<string | undefined>(undefined);
  timeStamp = signal<string | undefined>(undefined);
  fixedTimeStamp = signal<string | undefined>(undefined);
  timeStampTime = signal<string | undefined>(undefined);
  fixedTimeStampTime = signal<string | undefined>(undefined);
  faultEffectId = signal<string | undefined>(undefined);
  faultEffectName = signal<string | undefined>(undefined);
  impactId = signal<number | undefined>(undefined);
  impactName = signal<string | undefined>(undefined);
  lastModificationTimeStamp = signal<Date | undefined>(undefined);
  incidentStateId = signal<number | undefined>(undefined);
  incidentStateName = signal<string | undefined>(undefined);
  incidentTitle = signal<string | undefined>(undefined);
  impactOptions = signal<IOption[]>([]);
  hasInterruptions = signal<boolean | undefined>(undefined);

  alarmButtons = signal<IButton[]>([]);
  stateButtons = signal<IButton[]>([]);
  impactButtons = signal<IButton[]>([]);
  faultButtons = signal<IButton[]>([]);
  checkCompleted = signal<boolean>(false);
  faultEffectLocationId = signal<string | undefined>(undefined);
  faultEffectLocationOptions = signal<IOption[]>([]);
  saveCompleted = output<string>();
  hasError = signal<boolean>(false);
  validationError = output<FormValidator>();
  accessCode = computed(() => {
    return this.incidentService.incidentDetails()?.accessCode;
  });
  tenantId = computed(() => {
    return this.tenantService.tenantId();
  });
  formValidator: FormValidator | undefined;
  saveTrigger = input<string | undefined>(undefined);

  _loadIncidentMeta = effect(() => {
    const accessCode = this.accessCode();
    const tenantId = this.tenantId();

    if (accessCode && tenantId) {
      untracked(() => {
        this.detailsService.loadDataFragment(DataFragments.incidentstates, accessCode);
        this.detailsService.loadDataFragment(DataFragments.incidentimpacts, accessCode);
        this.detailsService.loadDataFragment(DataFragments.incidentcategories, accessCode);

        this.detailsService.loadDataFragment(DataFragments.faulteffects, accessCode, tenantId);
      });
    }
  });

  _initDetailsMeta = effect(() => {
    const states = this.detailsService.fragmentToSignal[DataFragments.incidentstates].dataSignal();
    const effects = this.detailsService.fragmentToSignal[DataFragments.faulteffects].dataSignal();
    const alarmCategories =
      this.detailsService.fragmentToSignal[DataFragments.incidentcategories].dataSignal();
    const impacts =
      this.detailsService.fragmentToSignal[DataFragments.incidentimpacts].dataSignal();
    const faultLocations =
      this.detailsService.fragmentToSignal[DataFragments.faultlocations].dataSignal();
    const _activeLanguage = this.langService.getLangSignal()();

    untracked(() => {
      let buttonStatesData = states?.map(state => {
        return {
          id: state.value,
          value: state.key as string,
          selected: this.incidentStateName() === state.value,
          title: this.translocoService.translate('home.filterState.' + state.value),
          colors: undefined,
        };
      });

      const alarmsButtonData = alarmCategories?.map(alarm => {
        return {
          id: alarm.value,
          value: alarm.key as string,
          selected: this.categoryName() === alarm.value,
          title: alarm.value as string,
          colors: undefined,
        };
      });

      let effectsData: IOption[] = [];
      if (effects) {
        effectsData = effects?.map(effect => {
          return {
            value: effect.key as string,
            label: this.translocoService.translate(('options.effects.' + effect.value) as string),
          };
        });
      }

      const impactsData = impacts?.map(impact => {
        return {
          id: impact.value,
          value: impact.key as string,
          selected: this.impactName() === impact.value,
          title: this.translocoService.translate('incidentDetail.' + impact.value),
          colors: undefined,
        };
      });

      const impactsBtnData = [
        {
          id: 'yes',
          value: 1,
          selected: this.hasInterruptions() === true ? true : false,
          title: this.translocoService.translate('core.yes'),
          colors: undefined,
        },
        {
          id: 'no',
          value: 0,
          selected: this.hasInterruptions() === false && this.hasInterruptions() !== undefined,
          title: this.translocoService.translate('core.no'),
          colors: undefined,
        },
      ];

      if (!this.incidentStateName() && this.mode() === 'edit') {
        buttonStatesData = states?.map((state, idx) => {
          return {
            id: state.value,
            value: state.key as string,
            selected: idx === 0,
            title: this.translocoService.translate('home.filterState.' + state.value),
            colors: undefined,
          };
        });
        this.incidentStateId.set(4);
      }

      if (this.mode() === 'create') {
        if (!this.incidentStateName()) {
          this.incidentStateId.set(3);
        }
      }

      if (this.incidentService.incidentDetails() !== undefined) {
        if (this.categoryId() === undefined) {
          this.formValidator?.checkRequired('alarmButtons', undefined);
        }

        if (this.impactId() === undefined) {
          this.formValidator?.checkRequired('impactButtons', undefined);
        }

        if (this.hasInterruptions() === undefined) {
          this.formValidator?.checkRequired('faultButtons', undefined);
        }

        this.formValidator?.checkRequired('impact', this.faultEffectId());
      }

      if (buttonStatesData) {
        this.stateButtons.set(buttonStatesData);
      }
      if (alarmsButtonData) {
        this.alarmButtons.set(alarmsButtonData);
      }
      if (impactsBtnData) {
        this.faultButtons.set(impactsBtnData);
      }
      if (impactsData) {
        this.impactButtons.set(impactsData);
      }
      if (effectsData) {
        this.impactOptions.set(effectsData);
      }
      if (faultLocations) {
        this.faultEffectLocationOptions.set(
          faultLocations.map(option => ({
            value: option.key as string,
            label: this.translocoService.translate('options.causeLocation.' + option.value),
          })),
        );
      }
    });
  });

  _ = effect(() => {
    if (!this.formValidator) {
      this.formValidator = new FormValidator(
        [
          {
            key: 'description',
            errorMessageSignal: signal(undefined),
            errorIdSignal: signal(undefined),
          },
          {
            key: 'impact',
            errorMessageSignal: signal(undefined),
            errorIdSignal: signal(undefined),
          },
          {
            key: 'faultDate',
            errorMessageSignal: signal(undefined),
            errorIdSignal: signal(undefined),
          },
          {
            key: 'faultTime',
            errorMessageSignal: signal(undefined),
            errorIdSignal: signal(undefined),
          },
          {
            key: 'alarmButtons',
            errorMessageSignal: signal(undefined),
            errorIdSignal: signal(undefined),
          },
          {
            key: 'faultButtons',
            errorMessageSignal: signal(undefined),
            errorIdSignal: signal(undefined),
          },
          {
            key: 'impactButtons',
            errorMessageSignal: signal(undefined),
            errorIdSignal: signal(undefined),
          },
        ],
        this.translocoService,
      );
    }
    const details = this.incidentService.incidentDetails();

    untracked(() => {
      this.categoryId.set(details?.incidentCategoryId ?? undefined);
      this.categoryName.set(details?.incidentCategoryName ?? undefined);
      this.creatorName.set(details?.incidentCreatorName ?? undefined);
      this.description.set(details?.incidentDescription ?? undefined);
      this.timeStamp.set(
        details?.incidentFaultTimeStamp
          ? dateToDay(new Date(details.incidentFaultTimeStamp).getTime())
          : undefined,
      );
      this.fixedTimeStamp.set(
        details?.incidentFixedTimeStamp
          ? dateToDay(new Date(details.incidentFixedTimeStamp).getTime())
          : undefined,
      );
      this.timeStampTime.set(
        details?.incidentFaultTimeStamp ? isoDateToTime(details.incidentFaultTimeStamp) : undefined,
      );
      this.fixedTimeStampTime.set(
        details?.incidentFixedTimeStamp ? isoDateToTime(details.incidentFixedTimeStamp) : undefined,
      );
      this.faultEffectId.set(details?.faultEffectId?.toString() ?? undefined);
      this.faultEffectName.set(details?.faultEffectName ?? undefined);
      this.impactId.set(details?.incidentImpactId ?? undefined);
      this.impactName.set(details?.incidentImpactName ?? undefined);
      this.lastModificationTimeStamp.set(details?.incidentLastModificationTimeStamp ?? undefined);
      this.incidentStateId.set(details?.incidentStateId ?? undefined);
      this.incidentStateName.set(details?.incidentStateName ?? undefined);
      this.incidentTitle.set(details?.incidentTitle ?? undefined);
      this.hasInterruptions.set(details?.hasInterruptions === 1);
      if (details?.hasInterruptions === undefined) {
        this.hasInterruptions.set(undefined);
      }
      this.faultEffectLocationId.set(details?.faultDamagedLocation);

      if (this.incidentService.incidentDetails() && !this.incidentTitle()) {
        this.incidentTitle.set(getTitle(this.translocoService, details?.voltages, details?.alertName, undefined, !!details?.hasInterruptions));
      }

      if (!this.timeStamp() && this.mode() === 'create') {
        const now = new Date();
        this.timeStamp.set(dateToDay(now.getTime()));
        this.timeStampTime.set(
          this.pad(now.getHours().toString(), 2) + ':' + this.pad(now.getMinutes().toString(), 2),
        );
      }
    });
  });

  pad(num: string, size: number) {
    num = num.toString();
    while (num.length < size) num = '0' + num;
    return num;
  }

  _triggerSave = effect(() => {
    const save = this.tileService.detailsChangedTimestamp();
    untracked(() => {
      if (save && this.tileService.saved().indexOf(save) === -1) {
        this.tileService.saved().push(save);
        this.onSave('confirm');
      }
    });
  });

  _skipTenantIdSave = effect(() => {
    const save = this.saveTrigger();
    if (!this.formValidator?.isValid && save) {
      this.formValidator?.showErrors();
      this.saveCompleted.emit('-1');
      return;
    }
    if (save) {
      this.onSave('confirm', true);
    }
  });

  _formValidationChanged = effect(() => {
    const validator = this.formValidator;
    if (validator) {
      const validationUpdated = validator.showErrorOnLoad();
      if (validationUpdated) {
        this.validationError.emit(validator);
      }
    }
  });

  _alarmValidationEffect = effect(() => {
    const alarm = this.categoryId();
    this.langService.getLangSignal()();

    untracked(() => {
      this.formValidator?.checkRequired('alarmButtons', alarm);
    });
  });

  _impactValidationEffect = effect(() => {
    const impact = this.impactId();
    this.langService.getLangSignal()();

    untracked(() => {
      this.formValidator?.checkRequired('impactButtons', impact);
    });
  });

  _effectValidationEffect = effect(() => {
    const effect = this.faultEffectId();
    this.langService.getLangSignal()();

    untracked(() => {
      this.formValidator?.checkRequired('impact', effect);
    });
  });

  _interruptionEffect = effect(() => {
    const effect = this.hasInterruptions();
    this.langService.getLangSignal()();

    untracked(() => {
      this.formValidator?.checkRequired('faultButtons', effect);
    });
  });

  _descriptionValidation = effect(() => {
    const description = this.description();

    if (this.incidentService.incidentDetails() && !description) {
      this.formValidator?.setError(
        'description',
        -1,
        this.translocoService.translate('validation.required'),
      );
    } else {
      const currentError = this.formValidator?.getField('description');
      if (currentError?.errorId() === -1) {
        this.formValidator?.clearError('description');
      }
    }

    if (this.formValidator) {
      this.validationError.emit(this.formValidator);
    }
  });

  _onRouteChanged = effect(() => {
    this.activatedRoute.snapshot.url.toString();
    const accessCode = this.activatedRoute.snapshot.paramMap.get('accessCode');
    if (this.mode() === 'create') {
      untracked(() => {
        this.incidentService.getIncidentDetails(accessCode as string);
      });
    }
  });

  alarmButtonsClick(selected: any) {
    if (selected.length > 0) {
      this.categoryId.set(selected[0]);
    }
  }

  stateButtonsClick(selected: any) {
    if (selected.length > 0) {
      this.incidentStateId.set(selected[0]);
    }
  }

  impactButtonsClick(selected: any) {
    if (selected.length > 0) {
      this.impactId.set(selected[0]);
    }
  }

  faultButtonsClick(selected: any) {
    if (selected.length > 0) {
      this.hasInterruptions.set(selected[0] == 1);
    }
  }

  closeCompletedDialog(action: 'confirm' | 'cancel') {
    this.checkCompleted.set(false);
    if (action === 'confirm') {
      this.onSave(action);
    }
  }

  formValidation(formError: IFormError | undefined, key: string) {
    if (formError) {
      this.formValidator?.setError(
        formError.formId,
        formError.value,
        formError.message,
        formError.replacement,
      );
    } else {
      this.formValidator?.clearError(key);
    }
    if (this.formValidator) {
      this.validationError.emit(this.formValidator);
    }
  }

  onSave(action: 'confirm' | 'cancel', skipTenantId?: boolean) {
    if (this.incidentStateId() == 7) {
      if (
        this.faultEffectId() === undefined ||
        this.faultEffectLocationId() === undefined ||
        this.faultEffectLocationId() === null
      ) {
        this.checkCompleted.set(true);
        return;
      }
    }

    const dateTimeFault = addTimeToDate(this.timeStamp(), this.timeStampTime());
    const dateTimeFixed = addTimeToDate(this.fixedTimeStamp(), this.fixedTimeStampTime());
    const incidentDetails = this.incidentService.incidentDetails();

    const savingData: IIncidentDetailsUpdateModel = {
      incidentCategoryId: this.categoryId(),
      incidentDescription: this.description(),
      incidentTitle: getTitle(
        this.translocoService,
        incidentDetails?.voltages,
        incidentDetails?.alertName,
        this.detailsService.fragmentToSignal[DataFragments.incidentimpacts]
          .dataSignal()
          ?.find(elem => {
            return elem.key == this.impactId();
          }),
          !!incidentDetails?.hasInterruptions
      ),
      faultEffectId: this.faultEffectId(),
      incidentStateId: this.incidentStateId(),
      incidentImpactId: this.impactId(),
      incidentFaultTimeStamp: dateTimeFault,
      incidentFixedTimeStamp: dateTimeFixed,
      hasInterruptions: this.hasInterruptions() === true ? 1 : 0,
    };

    const apiData = { ...(incidentDetails as IIncidentUpdateModel), ...savingData };
    const faultLocationId = this.faultEffectLocationId();
    if (faultLocationId) {
      apiData.faultLocationId = parseInt(faultLocationId);
    }

    const accessCode = this.accessCode();
    const tenantId = !skipTenantId ? this.tenantId() : undefined;
    if (action === 'confirm' && accessCode) {
      this.incidentService.update(accessCode, tenantId, apiData);
    }

    this.saveCompleted.emit(Date.now().toString());
  }
}
