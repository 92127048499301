<section class="bg-white dark:bg-gray-900">
  @if (incidentService.incidentStatisticsLoading() || incidentService.incidentReviewLoading()) {
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="p-8 bg-white rounded shadow-lg">
        <axpo-spinner size="normal">{{ 'core.loading' | transloco }}</axpo-spinner>
      </div>
    </div>
  }
  <div class="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
    <axpo-typography [variant]="'title-xl'" [weight]="'bold'">
      {{ 'core.statistics' | transloco }}
    </axpo-typography>
    <div class="flex pt-4">
      <axpo-button
        (click)="allStatisticsBtn.set('primary')"
        type="ghost"
        [style]="allStatisticsBtn()"
      >
        <axpo-typography [variant]="'body-l'">
          {{ 'core.allFaults' | transloco }}
        </axpo-typography>
      </axpo-button>
      <axpo-button
        (click)="allStatisticsBtn.set('secondary')"
        type="ghost"
        [style]="stationStatisticsBtn()"
      >
        <axpo-typography [variant]="'body-l'">
          {{ 'core.faultPerStation' | transloco }}
        </axpo-typography>
      </axpo-button>
    </div>
    @if (allStatisticsBtn() === 'primary') {
      <div class="p-4 mb-8 space-y-4 rounded-lg shadow-lg">
        <div class="flex flex-wrap">
          <div class="flex-initial w-48 grow md:grow-0 md:mr-4">
            <axpo-form-element
              formId="creationDateFrom"
              [label]="'home.creationDateFrom' | transloco"
              formType="date"
              [(value)]="filterFromDate"
              (valueChange)="searchFilter()"
            />
          </div>
          <div class="flex-initial w-48 grow md:grow-0 md:mr-4">
            <axpo-form-element
              formId="creationDateTo"
              [label]="'home.creationDateTo' | transloco"
              formType="date"
              [(value)]="filterToDate"
              (valueChange)="searchFilter()"
            />
          </div>
          <div class="flex-1 w-96 grow">
            <p
              class="font-normal font-open-sans leading-[24px] text-[14px] tracking-[-0.14px] ng-star-inserted"
            >
              {{ 'home.state' | transloco }}
            </p>
            <axpo-selectable-buttons
              [buttons]="stateButtons()"
              [multiselect]="true"
              (buttonClicked)="stateFilter($event)"
            ></axpo-selectable-buttons>
          </div>
        </div>
        <div>
          <axpo-button [autofocus]="true" (click)="exportClicked()">
            {{ 'core.export' | transloco }}
          </axpo-button>
        </div>
      </div>
      <div class="p-4 mb-8 space-y-4 rounded-lg shadow-lg">
        <axpo-typography [variant]="'title-l'" [weight]="'bold'">
          {{ 'core.processingTime' | transloco }}
        </axpo-typography>
        <div class="flex justify-start gap-8">
          <div>
            <i class="text-7xl fas fa-clock text-text-secondary"></i>
          </div>
          <div>
            <axpo-typography [variant]="'body-m'">
              {{ solutionTimeMin() }} {{ 'core.minutes' | transloco }}
            </axpo-typography>
            <axpo-typography [variant]="'body-m'">
              {{ solutionTimeHour() }}
            </axpo-typography>
            <axpo-typography [variant]="'body-m'">
              {{ solutionTimeDay() }}
            </axpo-typography>
          </div>
        </div>
      </div>
      <div class="p-4 mb-8 space-y-4 rounded-lg shadow-lg">
        <axpo-typography [variant]="'title-l'" [weight]="'bold'">
          {{ 'incidentDetail.incidentResolution.incidentSource' | transloco }}
        </axpo-typography>
        <axpo-table
          [columns]="incidentSourceColumns()"
          [data]="incidentSourceData()"
          [isLoading]="incidentService.incidentStatisticsLoading()"
          [chartMax]="chartMaxSource()"
          (sortClick)="onSort($event, incidentSourceData)"
        ></axpo-table>
      </div>
      <div class="p-4 mb-8 space-y-4 rounded-lg shadow-lg">
        <axpo-typography [variant]="'title-l'" [weight]="'bold'">
          {{ 'incidentDetail.incidentResolution.incidentLocation' | transloco }}
        </axpo-typography>
        <axpo-table
          [columns]="incidentLocationColumns()"
          [data]="incidentLocationData()"
          [isLoading]="incidentService.incidentStatisticsLoading()"
          [chartMax]="chartMaxLocation()"
          (sortClick)="onSort($event, incidentLocationData)"
        ></axpo-table>
      </div>
      <div class="p-4 mb-8 space-y-4 rounded-lg shadow-lg">
        <axpo-typography [variant]="'title-l'" [weight]="'bold'">
          {{ 'incidentDetail.incidentResolution.damageLine' | transloco }}
        </axpo-typography>
        <axpo-table
          [columns]="incidentDamageLineColumns()"
          [data]="incidentDamageLineData()"
          [isLoading]="incidentService.incidentStatisticsLoading()"
          [chartMax]="chartMaxDamageLine()"
          (sortClick)="onSort($event, incidentDamageLineData)"
        ></axpo-table>
      </div>
      <div class="p-4 mb-8 space-y-4 rounded-lg shadow-lg">
        <axpo-typography [variant]="'title-l'" [weight]="'bold'">
          {{ 'incidentDetail.incidentResolution.damagePlant' | transloco }}
        </axpo-typography>
        <axpo-table
          [columns]="incidentDamagePlantColumns()"
          [data]="incidentDamagePlantData()"
          [isLoading]="incidentService.incidentStatisticsLoading()"
          [chartMax]="chartMaxDamagePlant()"
          (sortClick)="onSort($event, incidentDamagePlantData)"
        ></axpo-table>
      </div>
      <div class="p-4 mb-8 space-y-4 rounded-lg shadow-lg">
        <axpo-typography [variant]="'title-l'" [weight]="'bold'">
          {{ 'incidentDetail.impacts' | transloco }}
        </axpo-typography>
        <axpo-table
          [columns]="incidentEffectColumns()"
          [data]="incidentEffectData()"
          [isLoading]="incidentService.incidentStatisticsLoading()"
          [chartMax]="chartMaxImpacts()"
          (sortClick)="onSort($event, incidentEffectData)"
        ></axpo-table>
      </div>
    } @else {
      <div class="p-4 mb-8 space-y-4 rounded-lg shadow-lg">
        <div class="flex flex-wrap">
          <div class="flex-initial w-48 grow md:grow-0 md:mr-4">
            <label
              for="filterSearchText"
              class="font-normal font-open-sans leading-[24px] text-[14px] tracking-[-0.14px]"
            >
              {{ 'home.searchTitle' | transloco }}
            </label>
            <axpo-search
              id="filterSearchText"
              [(searchValue)]="filterSearchText"
              (debounceValue)="searchFilter()"
              [debounceTime]="500"
              [debounce]="true"
            ></axpo-search>
          </div>
          <div class="flex-initial w-48 grow md:grow-0 md:mr-4">
            <axpo-form-element
              formId="creationDateFrom"
              [label]="'home.creationDateFrom' | transloco"
              formType="date"
              [(value)]="filterFromDate"
              (valueChange)="searchFilter()"
            />
          </div>
          <div class="flex-initial w-48 grow md:grow-0 md:mr-4">
            <axpo-form-element
              formId="creationDateTo"
              [label]="'home.creationDateTo' | transloco"
              formType="date"
              [(value)]="filterToDate"
              (valueChange)="searchFilter()"
            />
          </div>
          <div class="pt-6">
            <axpo-button [autofocus]="true" (click)="exportClicked()">
              {{ 'core.export' | transloco }}
            </axpo-button>
          </div>
        </div>
      </div>
      <div class="p-4 mb-8 space-y-4 rounded-lg shadow-lg">
        <div class="flex flex-wrap items-center justify-center">
          <span class="align-middle">
            {{ incidentReviewData().length }} {{ 'home.incidentsFound' | transloco }}
          </span>
          <axpo-button (click)="refresh()" [type]="'ghost'">
            <i class="mr-3 text-xl fas fa-refresh" aria-hidden="true"></i>
            {{ 'home.refresh' | transloco }}
          </axpo-button>

          <app-timer [timerSeconds]="60" (timerElapsed)="refresh()"></app-timer>
        </div>
        <axpo-table
          [columns]="incidentReviewColumns()"
          [data]="incidentReviewData()"
          [isLoading]="incidentService.incidentReviewLoading()"
          (sortClick)="onSort($event, incidentReviewData)"
          dateTimeFormat="dd.MM.yyyy - HH:mm"
        ></axpo-table>
      </div>
    }
  </div>
</section>
