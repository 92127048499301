@if (timer()) {
  <div class="relative float-left">
    <button
      (click)="stopTimer()"
      (mouseover)="toggleRunningTimer(true)"
      (focus)="toggleRunningTimer(true)"
      (mouseout)="toggleRunningTimer(false)"
      (blur)="toggleRunningTimer(false)"
      class="text-primary w-8 h-8 absolute z-10"
      style="top: 2px; left: 3px"
    >
      @if (runningTimerHover()) {
        <i class="fas fa-stop" aria-hidden="true"></i>
      } @else {
        <span class="font-bold">{{ timeRemainingUntilRefresh() }}</span>
      }
    </button>
    <svg
      width="38"
      height="38"
      xmlns="http://www.w3.org/2000/svg"
      style="-webkit-transform: rotate(-90deg); transform: rotate(-90deg)"
    >
      <g>
        <title>Layer 1</title>
        <circle
          id="circle"
          r="17.5"
          cy="19"
          cx="19"
          stroke-width="2"
          stroke="#cd2626"
          fill="none"
          style="stroke-dasharray: 110; stroke-dashoffset: 110; transition: all 1s linear"
          [style.strokeDashoffset]="strokeDashoffset()"
        />
      </g>
    </svg>
  </div>
}
@if (!timer()) {
  <button
    (click)="startTimer()"
    class="text-primary w-8 h-8"
    style="width: 38px; height: 38px"
    (mouseover)="toggleStartTimer()"
    (focus)="toggleStartTimer()"
    (mouseout)="toggleStartTimer()"
    (blur)="toggleStartTimer()"
  >
    <i class="text-xl fas" [class]="startTimerClass()" aria-hidden="true"></i>
  </button>
}
