import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';

import { AxpoButtonComponent } from '../../core/axpo-button/axpo-button.component';
import {
  AxpoSelectableButtonsComponent,
  IButton,
} from '../../core/axpo-selectable-buttons/axpo-selectable-buttons.component';
import { AxpoSpinnerComponent } from '../../core/axpo-spinner/axpo-spinner.component';
import { AxpoStepperComponent } from '../../core/axpo-stepper/axpo-stepper.component';
import {
  AxpoTableComponent,
  ITableColumn,
  ITableRow,
} from '../../core/axpo-table/axpo-table.component';
import { AxpoTableNavigationComponent } from '../../core/axpo-tablenavigation/axpo-tablenavigation.component';
import { AxpoTypographyComponent } from '../../core/axpo-typography/axpo-typography.component';
import { FileDropComponent } from '../../shared/controls/attachments/filedrop/filedrop.component';
import { ILocationImportModel } from '../../shared/models/api_models';
import { LanguageService } from '../../shared/services/lang.service';
import { LocationImportService } from '../../shared/services/location-import.service';
import { TenantService } from '../../shared/services/tenant.service';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    TranslocoPipe,
    AxpoTypographyComponent,
    AxpoButtonComponent,
    FileDropComponent,
    AxpoStepperComponent,
    AxpoTableComponent,
    AxpoTableNavigationComponent,
    AxpoSelectableButtonsComponent,
    AxpoSpinnerComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './import-location.component.html',
})
export class ImportLocationComponent {
  translocoService = inject(TranslocoService);
  tenantService = inject(TenantService);
  importLocationService = inject(LocationImportService);
  languageService = inject(LanguageService);
  private toastr = inject(ToastrService);
  private location = inject(Location);

  activeStep = signal<number>(0);
  file = signal<File | undefined>(undefined);
  selectedPageOfDeactivated = signal<number>(1);
  pageSizeOfDeactivated = signal<number>(10);
  selectedPageOfActivated = signal<number>(1);
  pageSizeOfActivated = signal<number>(10);
  selectedPageOfChanged = signal<number>(1);
  pageSizeOfChanged = signal<number>(10);
  selectedTab = signal<string | number>('1');
  buttons = signal<IButton[]>([]);
  columns = signal<ITableColumn[]>([]);
  steps = signal<string[]>([]);
  showWarnings = signal<boolean>(false);
  showErrors = signal<boolean>(false);

  translatedWarnings = signal<string[]>([]);
  translatedErrors = signal<string[]>([]);

  maxUploadBytes: number = 1024 * 1024 * 1500;

  _translateMessages = effect(() => {
    const validationWarnings = this.importLocationService.validationDetails()?.validationWarnings;
    const validationErrors = this.importLocationService.validationDetails()?.validationErrors;
    untracked(() => {
      this.translatedWarnings.set(
        validationWarnings?.map(message => {
          return this.importLocationService.TransformValidationMessagesToGenreicStrings(message);
        }) ?? [],
      );

      this.translatedErrors.set(
        validationErrors?.map(message => {
          return this.importLocationService.TransformValidationMessagesToGenreicStrings(message);
        }) ?? [],
      );
    });
  });

  _lang = effect(() => {
    this.languageService.getLangSignal()();
    const activated = this.amountOfActivated();
    const changed = this.amountOfChanged();
    const deactivated = this.amountOfDeactivated();
    untracked(() => {
      const translatedButtons = [
        {
          id: '1',
          value: '1',
          selected: this.selectedTab() == '1',
          title:
            activated + ' ' + this.translocoService.translate('import-location.activatedLocations'),
          colors: undefined,
        },
        {
          id: '2',
          value: '2',
          selected: this.selectedTab() == '2',
          title:
            changed + ' ' + this.translocoService.translate('import-location.changedLocations'),
          colors: undefined,
        },
        {
          id: '3',
          value: '3',
          selected: this.selectedTab() == '3',
          title:
            deactivated +
            ' ' +
            this.translocoService.translate('import-location.deactivatedLocations'),
          colors: undefined,
        },
      ] as IButton[];
      this.buttons.set(translatedButtons);

      const translatedColumns = [
        {
          title: this.translocoService.translate('import-location.tableId'),
          field: 'id',
          sortable: false,
        },
        {
          title: this.translocoService.translate('import-location.tableDescription'),
          field: 'description',
          sortable: false,
        },
        {
          title: this.translocoService.translate('import-location.tableBezId'),
          field: 'name',
          sortable: false,
        },
        {
          title: this.translocoService.translate('import-location.tableType'),
          field: 'locationTypeName',
          sortable: false,
        },
        {
          title: this.translocoService.translate('import-location.tableLatitude'),
          field: 'longitude',
          sortable: false,
        },
        {
          title: this.translocoService.translate('import-location.tableLongitude'),
          field: 'latitude',
          sortable: false,
        },
      ];
      this.columns.set(translatedColumns);

      const translatedSteps = [
        this.translocoService.translate('import-location.step1'),
        this.translocoService.translate('import-location.step2'),
      ];

      this.steps.set(translatedSteps);
    });
  });

  isFormValid = computed(() => {
    if (this.file()) {
      return true;
    }
    return false;
  });

  importVerified = computed(() => {
    return this.amountOfErrors() === 0 && this.totalAmount() > 0;
  });

  totalAmount = computed(() => {
    return this.amountOfActivated() + this.amountOfDeactivated() + this.amountOfChanged();
  });

  amountOfActivated = computed(() => {
    return this.importLocationService.validationDetails()?.activated?.length || 0;
  });

  dataOfActivated = computed(() => {
    const details = this.importLocationService.validationDetails();
    const activated = details?.activated ?? [];
    return (
      this.paginate(activated, this.pageSizeOfActivated(), this.selectedPageOfActivated()).map(
        importModel => {
          return this.mapToTableRow(importModel);
        },
      ) || []
    );
  });

  amountOfDeactivated = computed(() => {
    return this.importLocationService.validationDetails()?.deactivated?.length || 0;
  });

  dataOfDeactivated = computed(() => {
    return (
      this.paginate(
        this.importLocationService.validationDetails()?.deactivated ?? [],
        this.pageSizeOfDeactivated(),
        this.selectedPageOfDeactivated(),
      ).map(importModel => {
        return this.mapToTableRow(importModel);
      }) || []
    );
  });

  pagesOfDeactivated = computed(() => {
    return this.amountOfDeactivated() / 10;
  });

  amountOfChanged = computed(() => {
    return this.importLocationService.validationDetails()?.changed?.length || 0;
  });

  dataOfChanged = computed(() => {
    return (
      this.paginate(
        this.importLocationService.validationDetails()?.changed ?? [],
        this.pageSizeOfChanged(),
        this.selectedPageOfChanged(),
      ).map(importModel => {
        return this.mapToTableRow(importModel);
      }) || []
    );
  });

  amountOfWarnings = computed(() => {
    return this.importLocationService.validationDetails()?.validationWarnings?.length || 0;
  });

  amountOfErrors = computed(() => {
    return this.importLocationService.validationDetails()?.validationErrors?.length || 0;
  });

  validationWarnings = computed(() => {
    return this.translatedWarnings();
  });

  validationErrors = computed(() => {
    return this.translatedErrors();
  });

  tabSelect(values: (string | number)[]): void {
    const value = values[0];
    this.selectedTab.set(value);
  }

  mapToTableRow(importModel: ILocationImportModel): ITableRow {
    return {
      id: { value: importModel.axpoId ?? '??' },
      name: { value: importModel.name ?? '??' },
      description: { value: importModel.description ?? '??' },
      locationTypeName: { value: importModel.locationTypeName ?? '??' },
      longitude: { value: importModel.coordinates?.longitude ?? '0' },
      latitude: { value: importModel.coordinates?.latitude ?? '0' },
    } as ITableRow;
  }

  paginate(
    array: ILocationImportModel[],
    page_size: number,
    page_number: number,
  ): ILocationImportModel[] {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  onBackClicked(): void {
    this.activeStep.update(currentStep => {
      currentStep--;
      return currentStep;
    });
  }

  onSubmitClicked(): void {
    if (this.activeStep() === 0 && this.file()) {
      this.importLocationService.upload(this.file() as any, false);
    }

    if (this.activeStep() === 1) {
      if (this.file()) {
        this.importLocationService.upload(this.file() as any, true);
      } else {
        this.importLocationService.uploadFromApi(true);
      }
    }

    this.activeStep.update(currentStep => {
      currentStep++;
      return currentStep;
    });
  }

  remove(): void {
    this.file.set(undefined);
  }

  filesUploaded(files: any): void {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      this.file.set(file);
      i++;
    }
  }

  startNewImport() {
    this.activeStep.set(0);
    this.remove();
  }
}
