import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, input, model, output, signal, untracked } from '@angular/core';

@Component({
  selector: 'axpo-stepper',
  standalone: true,
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './axpo-stepper.component.html',
  styleUrl: './axpo-stepper.component.css',
})
export class AxpoStepperComponent {
  steps = input.required<string[]>();
  activeStep = model.required<number>();
  allowForwardStep = input<boolean>(false);
  validateNavigation = input<boolean>(false);
  navigationValid = model<boolean>(false);
  beforeNavigate = output<number>();
  navigateTo = signal<number>(0);

  isStepActive(index: number): boolean {
    const currentStep = this.activeStep();
    return index < currentStep || (this.allowForwardStep() && index === currentStep + 1);
  }

  onBeforeNavigate(index: number) {
    this.beforeNavigate.emit(index - this.activeStep());
    this.navigateTo.set(index);
    if (!this.validateNavigation()) {
      this.activeStep.set(this.navigateTo());
    }
  }

  _navigationValidated = effect(() => {
    const navigationValidated = this.navigationValid();

    if (navigationValidated) {
      untracked(() => {
        this.navigationValid.set(false);
        this.activeStep.set(this.navigateTo());
      });
    }
  });
}