@if (addNewContactDialog()) {
  <axpo-dialog
    title="{{ getContactDialogTitle() | transloco }}"
    cancelText="{{ 'core.cancel' | transloco }}"
    confirmText="{{ 'core.save' | transloco }}"
    textAlign="left"
    [processBeforeClose]="true"
    [loadingStateText]="'core.loading' | transloco"
    [dataLoaded]="newContactProcessed()"
    (takenAction)="contactDialogClosed($event)"
    [saveDisabled]="!formManager?.isValid && !!formManager?.showErrorOnLoad()"
    [abortSaving]="abortSaving()"
  >
    <div class="flex flex-col space-y-4">
      <axpo-form-element
        formId="designation"
        label="{{ 'incidentDetail.contactsData.designation' | transloco }}"
        formType="text"
        [(value)]="newContactDesignation"
        [min]="5"
        [max]="100"
        (errorEmitter)="formValidation($event, 'designation')"
        [errorMessageInput]="this.formManager?.getField('designation')?.errorMessage()"
        [required]="true"
      ></axpo-form-element>
      <axpo-form-element
        formId="name"
        label="{{ 'incidentDetail.contactsData.name' | transloco }}"
        formType="text"
        [(value)]="newContactName"
        [min]="5"
        [max]="100"
        (errorEmitter)="formValidation($event, 'name')"
        [errorMessageInput]="this.formManager?.getField('name')?.errorMessage()"
        [required]="true"
      ></axpo-form-element>
      <axpo-form-element
        formId="phone"
        label="{{ 'incidentDetail.contactsData.phone' | transloco }}"
        formType="tel"
        pattern="\+\d{5,18}"
        [(value)]="newContactPhone"
        [min]="1"
        [max]="18"
        (errorEmitter)="formValidation($event, 'phone')"
        [errorMessageInput]="this.formManager?.getField('phone')?.errorMessage()"
        [required]="true"
      ></axpo-form-element>
      <axpo-form-element
        formId="mail"
        label="{{ 'incidentDetail.contactsData.mail' | transloco }}"
        formType="email"
        [(value)]="newContactMail"
        [pattern]="'[a-zA-Z0-9_%+-]+\.[a-zA-Z0-9_%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}'"
        (errorEmitter)="formValidation($event, 'mail')"
        [errorMessageInput]="this.formManager?.getField('mail')?.errorMessage()"
        [required]="true"
      ></axpo-form-element>
      <axpo-form-element
        formId="isExecutor"
        label="{{ 'incidentDetail.shareLinkDialog.executing' | transloco }}"
        formType="checkbox"
        [(value)]="newContactIsExecuting"
      ></axpo-form-element>
    </div>
  </axpo-dialog>
}
<div class="flex flex-col pt-4 space-y-4">
  <div class="flex space-x-4">
    <div class="flex space-x-4">
      <i class="text-2xl fas fa-info-circle text-primary"></i>
      <axpo-typography>
        {{ 'core.personToInform' | transloco }}
      </axpo-typography>
    </div>
    <div class="flex space-x-4">
      <svg class="h-6 fill-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          d="M482 363.58V316c0-49.388-15.925-96.331-46.053-135.753-26.743-34.993-63.719-61.83-105.003-76.443C329.795 80.007 310.077 61 286 61h-60c-24.077 0-43.795 19.007-44.944 42.804-41.284 14.613-78.26 41.45-105.003 76.443C45.925 219.669 30 266.612 30 316v47.58C12.541 369.772 0 386.445 0 406c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 0-19.555-12.541-36.228-30-42.42zM60 316c0-54.091 23.106-104.728 61-140.855V226c0 8.284 6.716 15 15 15s15-6.716 15-15v-74.383a195.128 195.128 0 0130-15.679V256c0 8.284 6.716 15 15 15s15-6.716 15-15V114.932 106c0-8.271 6.729-15 15-15h60c8.271 0 15 6.729 15 15v150c0 8.284 6.716 15 15 15s15-6.716 15-15V135.938a195.304 195.304 0 0130 15.679V226c0 8.284 6.716 15 15 15s15-6.716 15-15v-50.855c37.894 36.127 61 86.764 61 140.855v45H60v-45zm407 105H45c-8.271 0-15-6.729-15-15s6.729-15 15-15h422c8.271 0 15 6.729 15 15s-6.729 15-15 15z"
        ></path>
      </svg>
      <axpo-typography>
        {{ 'core.executingPerson' | transloco }}
      </axpo-typography>
    </div>
    <div class="hidden space-x-4 sm:flex">
      <i class="text-2xl fas fa-check text-primary"></i>
      <axpo-typography>
        {{ 'core.wakeUpCallSuccessful' | transloco }}
      </axpo-typography>
    </div>
  </div>
  @for (contact of contacts(); track contact.id) {
    <div class="flex items-center justify-between pb-4 border-b-2 border-border-soft-300">
      <div class="flex flex-none">
        @if (contact.isExecutor === true) {
          <axpo-button
            (click)="toggleExecutor(contact)"
            class="float-right"
            variant="svg"
            [type]="'ghost'"
            [style]="'secondary'"
            [disabled]="isBlocked()"
          >
            <svg class="h-6 fill-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                d="M482 363.58V316c0-49.388-15.925-96.331-46.053-135.753-26.743-34.993-63.719-61.83-105.003-76.443C329.795 80.007 310.077 61 286 61h-60c-24.077 0-43.795 19.007-44.944 42.804-41.284 14.613-78.26 41.45-105.003 76.443C45.925 219.669 30 266.612 30 316v47.58C12.541 369.772 0 386.445 0 406c0 24.813 20.187 45 45 45h422c24.813 0 45-20.187 45-45 0-19.555-12.541-36.228-30-42.42zM60 316c0-54.091 23.106-104.728 61-140.855V226c0 8.284 6.716 15 15 15s15-6.716 15-15v-74.383a195.128 195.128 0 0130-15.679V256c0 8.284 6.716 15 15 15s15-6.716 15-15V114.932 106c0-8.271 6.729-15 15-15h60c8.271 0 15 6.729 15 15v150c0 8.284 6.716 15 15 15s15-6.716 15-15V135.938a195.304 195.304 0 0130 15.679V226c0 8.284 6.716 15 15 15s15-6.716 15-15v-50.855c37.894 36.127 61 86.764 61 140.855v45H60v-45zm407 105H45c-8.271 0-15-6.729-15-15s6.729-15 15-15h422c8.271 0 15 6.729 15 15s-6.729 15-15 15z"
              ></path>
            </svg>
          </axpo-button>
        } @else {
          <axpo-button
            (click)="toggleExecutor(contact)"
            class="float-right"
            variant="svg"
            [type]="'ghost'"
            [style]="'secondary'"
            [disabled]="isBlocked()"
          >
            <i class="text-2xl fas fa-info-circle text-primary"></i>
          </axpo-button>
        }
        <div class="w-[24px]">
          @if (contact.callNotificationStateId === 3) {
            <i class="relative text-2xl fas fa-check text-primary top-3 left-2"></i>
          }
        </div>
      </div>

      <div class="flex-1 md:ml-8">
        <axpo-typography variant="body-s">
          <span class="text-text-secondary">{{ contact.designation }}</span>
        </axpo-typography>
        <axpo-typography variant="body-m">
          {{ contact.name }}
        </axpo-typography>
        <axpo-typography variant="body-s">
          @if (contact.phone) {
            <span class="text-text-secondary">{{ contact.phone }}</span>
            |
          }
          <span class="text-text-secondary">{{ contact.mail }}</span>
        </axpo-typography>
      </div>

      <div class="flex flex-none space-x-4">
        @if (isBlocked()) {
          @if (contact.phone) {
            <a href="tel:{{ contact.phone }}">
              <i class="text-xl fas fa-phone text-text-secondary"></i>
            </a>
          }
          @if (contact.mail) {
            <a href="mailto:{{ contact.mail }}">
              <svg
                class="text-2xl w-[24px] h-[24px]"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M64 112c-8.8 0-16 7.2-16 16l0 22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1l0-22.1c0-8.8-7.2-16-16-16L64 112zM48 212.2L48 384c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-171.8L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z"
                  class="fill-text-secondary"
                />
              </svg>
            </a>
          }
        } @else {
          <div
            role="button"
            (click)="addNewContact(contact)"
            tabindex="0"
            aria-hidden="true"
            class="w-8"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="operation/edit">
                <mask
                  id="mask0_10469_12808"
                  style="mask-type: alpha"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_10469_12808)">
                  <path
                    id="vector"
                    d="M3 21.5164C2.73205 21.5164 2.4984 21.4199 2.29905 21.227C2.09968 21.034 2 20.7972 2 20.5164C2 20.2485 2.09968 20.0148 2.29905 19.8154C2.4984 19.6161 2.73205 19.5164 3 19.5164H21C21.268 19.5164 21.5016 19.6129 21.701 19.8059C21.9003 19.9988 22 20.2356 22 20.5164C22 20.7844 21.9003 21.018 21.701 21.2174C21.5016 21.4167 21.268 21.5164 21 21.5164H3ZM5.80768 16.5164C5.57563 16.5164 5.383 16.4398 5.2298 16.2866C5.0766 16.1334 5 15.9408 5 15.7087V14.4799C5 14.3722 5.01859 14.2718 5.05578 14.1789C5.09294 14.0859 5.15512 13.9959 5.2423 13.9087L13.8192 5.37023L16.1462 7.69715L7.6077 16.2741C7.52052 16.3613 7.43045 16.4234 7.3375 16.4606C7.24455 16.4978 7.14423 16.5164 7.03655 16.5164H5.80768ZM6 15.5164H6.9L14.7308 7.69715L13.8192 6.78563L6 14.6164V15.5164ZM17.0404 6.71255L14.7135 4.42408L15.8596 3.27793C16.043 3.09075 16.2699 2.99811 16.5404 3.00003C16.8109 3.00196 17.0378 3.0946 17.2212 3.27793L18.1481 4.20486C18.3314 4.38819 18.4295 4.61415 18.4423 4.88273C18.4551 5.15133 18.3699 5.37922 18.1866 5.56641L17.0404 6.71255Z"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div
            role="button"
            (click)="deleteContact(contact)"
            tabindex="0"
            aria-hidden="true"
            class="w-8"
          >
            <i class="fas fa-trash text-text-secondary w-[24px] h-[24px] text-xl"></i>
          </div>
        }
      </div>
    </div>
  }

  @if (!isBlocked()) {
    <div>
      <div class="pb-4">
        <axpo-search
          placeholder="{{ 'incidentDetail.contactsData.addExistingContact' | transloco }}"
          [(searchValue)]="filterContactSearch"
          (debounceValue)="searchValueChanged($event)"
          [debounceTime]="500"
          [debounce]="true"
        ></axpo-search>
        <app-search-result-list
          [entries]="searchQueryResult()"
          [isLoading]="detailsService.isLoadingSearch()"
          (entryAdded)="searchEntrySelected($event)"
          [isMenuOpen]="showSearchBar()"
          (emptyActionClick)="addNewContact(null)"
          emptyPlaceholderText="{{ 'incidentDetail.contactsData.placeholder' | transloco }}"
        ></app-search-result-list>
      </div>
      <axpo-button
        (click)="addNewContact(null)"
        class="float-right"
        variant="default"
        [type]="'outlined'"
        [style]="'secondary'"
      >
        <i class="text-sm fa fa-user-plus text-primary"></i>
        <axpo-typography class="ml-2">
          {{ 'incidentDetail.contactsData.addContact' | transloco }}...
        </axpo-typography>
      </axpo-button>
    </div>
    @if (contactService.validationMessages().length > 0) {
      <div class="p-4 bg-yellow-100">
        @for (message of contactService.validationMessages(); track message) {
          {{ contactService.TransformValidationMessagesToGenreicStrings(message) }}
        }
      </div>
    }
  }
</div>
@if (isLoading()) {
  <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="p-8 bg-white rounded shadow-lg">
      <axpo-spinner size="normal">{{ 'core.loading' | transloco }}</axpo-spinner>
    </div>
  </div>
}
